import { invalid } from 'moment';

/* eslint-disable import/no-anonymous-default-export */
export default {
  base: {
    log_in: 'Login',
    record: 'record',
    records: 'records',
    password: 'Password',
    confirm_password: 'Confirm Password',
    email: 'Email Address',
    work_start: 'Work Start',
    work_end: 'Work End',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
    import: 'Import',
    choose_file: 'Choose file',
    active: 'Active',
    search: 'Search',
    search_for: 'Search For',
    export_to_excel: 'Export To Excel',
    status: 'Status',
    edit: 'Edit',
    permission: 'Permission',
    more_search: 'More Search',
    search_source: 'Search Source',
    reset: 'Reset',
    save_all_check: 'Save All Check',
    copy_role_team: 'Copy Role Team',
    change_pass: 'Change Password',
    enter_current_password: 'Enter Current Password',
    enter_new_password: 'Enter New Password',
    re_enter_new_password: 'Re-enter New Password',
    confirm_statue_active: 'This status will change to "Active".\nAre you sure?',
    confirm_statue_inactive: 'This status will change to "Inactive".\nAre you sure?',
    download_template: 'Download Template',
    update_data_err: 'Data saved Faild',
    Change: 'Change',
    Cancel: 'Cancel',
    Logout: 'Logout',
    Change_password: 'Change Password',
    Please_Select: 'Please Select',
    Please_enter: 'Please enter',
    your_email_address: 'Your email address',
    Active: 'Active',
    prev: 'Previous',
    next: 'Next',
    first: 'First',
    last: 'Last',
    total: 'Total',
    of: 'of',
    no_items: 'There are no results',
    action: 'Action',
    no: 'No',
    create: 'Create',
    error: 'Error',
    session_expired: 'Login session has expired',
    please_login_again: 'Please log in again to continue using the app',
    not_belong_room: `You don't belong in this room`,
    not_permission: 'You are not allowed to access this page',
    loading: 'Loading...',
    ok: 'Ok',
    set_active_status_conflict: 'User status is existed',
    set_block_login_status_conflict: 'User block login status is existed',
    set_active_status_bad_request: 'User not exists',
    add_new_ip: 'Add New IP',
    close: 'Close',
    add_admin_success: 'Successfully added admin to the room',
    revoke_admin_success: 'Successfully revoked admin to the room',
    user_not_exists: 'User not exists',
    user_is_admin_room: 'User is admin in this room',
    user_is_not_admin_room: 'User is not admin in this room',
    revoke_admin_room: 'Your room management rights have been revoked',
    add_admin_room: `You have been selected as admin of room`,
    remove_admin_room: `You have been removed as the admin of the room`,
    unable_block_login: 'Unable to block login for user',
    unlock: 'UNLOCK',
    apply: 'Apply',
    profile: 'Profile',
    vocabulary: 'Vocabulary',
    clear: 'Clear',
    room_setting : 'Room setting',
  },
  nav: {
    admin: 'Admin',
    branch_list: 'Branch Management',
    group: 'Group Management',
    user_list: 'User Management',
    team_management: 'Team Management',
    team_name_manager: 'Team Management',
    add_new_user: 'Add New User',
    user_log: 'User Log',
    permission_access: 'Permission Access',
    city_list: 'City List Management',
    setting_call_block: 'Setting Call Block',
    extension_white_list: 'Extension White List',
    master_manager: 'Master Management',
  },
  master_manager: {
    detail_branch: 'Detail Branch',
    branch_name: 'Branch Name',
    branch_code: 'Branch Code',
    branch_prefix: 'Branch Prefix',
    pbx_link: 'PBX Link',
    branch_logo: 'Branch Logo',
    setting_call_block: 'Setting Call Block',
  },

  group_manager: {
    add_group: 'Add Group',
    Add_Edit_Group: 'Add/Edit Group',
    group: 'Group',
    group_name: 'Group Name',
    oder_by: 'Oder By',
    department: 'Department',
    tele_department: 'Tele Deparment',
    event_department: 'Event Deparment',
    account_department: 'Account Department',
    sale_department: 'Sale Department',
    admin_istractor: 'Administractor',
    database_department: 'Database Department',
    customer_service: 'Customer Sevice',
  },
  user_list: {
    user_list: 'User List',
    add_news_user: 'Add News User',
    edit_user: 'Edit User',
    import_user_list: 'Import User List',
    id_username: 'ID/Username',
    ext: 'EXT',
    full_name: 'Full Name',
    role: 'Role',
    team_management: 'Team Name',
    type_of_user: 'Type Of User',
    add_import: 'Add/Import',
    allow_ip: 'Allow IP',
    allow_export_showup: 'Allow Export ShowUp',
    allow_view_data_info: 'Allow View Data Info',
    allow_export_detail_event_info: 'Allow Export Detail Event Info',
    merge_contact: 'Merge Contact',
    show_mobile_showup: 'Show Mobile Showup',
    recall_checkin: 'Recall CheckIn',
    user_name: 'Username',
  },

  team_management: {
    team_management: 'Team Management',
    group: 'Group',
    branch: 'Branch',
    Add_Edit_Team: 'Add/Edit Team',
    List_Team: 'List Team',
    Team_Name: 'Team Name',
  },
  city_list: {
    list_city: 'City List',
    region: 'Region',
    city_name: 'City Name',
    priority: 'Priority',
    phone_code: 'Phone Code',
  },

  setting_call_block: {
    setting_call_block: 'Setting Call Block',
    number_of_call: 'Number Of Call',
    number_of_day_locker: 'Number Of Days Locked',
  },

  set_permission: {
    set_permission: 'Set Permission',
    team_mame: 'Team Name',
    group_function: 'Group Function',
    team_name_copy: 'Team Name (Copy)',
    copy_role_team: 'Copy Role Team',
    select: 'Select',
    role: 'Role',
    funciton_name: 'Function Name',
  },

  login: {
    username: 'Username',
    enter_username: 'Enter Username',
    password: 'Password',
    enter_password: 'Enter Password',
    email: 'Email',
    Log_In: 'Log In',
    Log_In_Admin: 'Log In Admin',
    Log_In_User: 'Log In User',
    username_or_password_is_incorrect: 'Your username or password is incorrect',
    OTP_send_success: 'OTP has been sent to the user email',
    other_login:
      'Your account has been logged out from this browser because you logged in on another browser',
    close_popup: 'Please close the popup and return to the login page',
    username_are_inactived: 'Your account has been marked as inactive',
    confirm_set_active: `Do you want to change the user's status?`,
    confirm_set_block_login: `Do you want to change the user's block login status?`,
    confirm_set_block_otp: `Do you want to change the user's block OTP status?`,
  },
  message: {
    required: 'This field is required',
    email_or_password_is_incorrect: 'Your email or password is incorrect',
    invalid_password:
      'Password must be from 8 to 20 characters long and contain these 0...9, A...z, special character',
    Password_does_not_match: 'Password does not match',
    Team_is_already_registered: 'Team is already registered',
    invalid_email: 'Please enter a valid email address',
    note_password:
      'Password must be from 8 to 20 characters long and contain these 0...9, A...z, special character',
    note_change_password:
      'Password must be from 8 to 20 characters long and contain these 0...9, A...z, special character',
    note_email:
      'Kindly provide your email address and check your inbox for further instructions on resetting your password after successful submission',
    contain_space: 'Username cannot use space characters',
    max_lenght_username: 'Username cannot exceed 12 characters',
    confirm_delete: 'Do you want to delete this message?',
    deleted_messages: 'This message is deleted!',
    request_gpt_timeout: 'Your request has expired, please try again',
    max_length_email: 'Email cannot exceed 254 characters',
  },
  changePassword: {
    Current_Password: 'Current Password',
    New_Password: 'New Password',
    Confirm_Password: 'Confirm Password',
  },
  account_management: {
    account_management: 'Account Management',
    username: 'Username',
    email: 'Email',
    password: 'Password',
    role: 'Role',
    add_account: 'Add Account',
    username_exits: 'Username already exists',
    email_exits: 'Email already exists',
    created_at: 'Created At',
    is_active: 'Status',
    created_by: 'Created By',
    language: 'Language',
    username_not_over_load_keyword: 'Username must not overlap with keyword',
    block_login: 'Block Login',
    block_otp: 'Block OTP',
    profile: 'Update Profile',
    change_role: 'Your role has been changed to "{{item_role}}"',
    change_my_role: 'You cannot change your role',
    must_have_at_least_1_account_with_role_super_admin:
      'Must have at least 1 account with role super admin',
  },
  room_management: {
    room_management: 'Room Management',
    name: 'Name',
    describe: 'Description',
    member: 'Member',
    add_room: 'Create Room',
    update_room: 'Update Room',
    room_name_exits: 'Room name already exists',
    update_member: 'Update Member',
    search_member: 'Search Member',
    created_at: 'Created At',
    created_by: 'Created By',
    update_ip: 'Update IP Address',
    public_ip: 'Client Public IP',
    note: 'Note',
    search_ip: 'Search Public IP',
    add_new_ip: 'Add New Public IP',
    update_ip_failed: 'Update IP Address failed',
    ip_is_existed: 'IP Address is already exists',
    user_is_not_admin_room: 'User is not an admin of the room',
    room_not_exist: 'Room does not exis',
    public_ip_exists: 'Public IP is existed',
    public_ip_not_exists: 'Public IP is not existed',
    public_ip_is_not_allowed: 'Public IP is not allowed',
    note_admin: 'Admin can only see the IP of the rooms they are admin in',
    from_room: 'From Room',
    switch_language_tooltip: 'Switch translate language (Ctrl + Alt + S)',
    room_not_found: 'Room not found',
  },
  public_ip_management: {
    public_ip_management: 'Public IP Management',
    public_ip: 'Public IP Address',
    note: 'Note',
    created_by: 'Created By',
    created_at: 'Created At',
    add_new_public_ip: 'Add New IP',
    invalid_note: 'Note limited to 255 characters',
    action: 'Action',
    confirm_set_status: `Do you want to change the status of this Public IP?`,
    public_ip_not_existed: `Public IP is not existed`,
    public_ip_status_existed: `Public IP status is existed`,
    create_public_ip_failed: `Add new Public IP address is failed`,
    public_ip_exists: 'Public IP is existed',
    required: `Note cannot be empty`,
    cannot_change_system_status:
      'You cannot change the system status when there are no IPs present within the system.',
    system_status: 'Status IP System',
    change_public_mode: 'When you change this IP status, the IP system will switch to public mode.',
    change_private_mode:
      'When you add this IP into the system, the system will switch to private mode.',
    option_add_ip_into_system: 'Add new IP from room',
    note_add_ip_in_system: 'Select the IPs of the room you want to add to the system',
    no_data_public_ip: 'There is no data when system is in public IP status',
    choose_your_ip: 'Choose your IP',
    note_set_allowed_up: 'Please choose IP when you want to switch the system to private',
  },
  otp: {
    enter_otp: 'Enter Your OTP',
    enter_otp_here: 'Enter OTP Here',
    submit: 'Submit',
    otp_expired: 'OTP code has expired',
    desc_otp:
      'The OTP code has been sent to your registered email, please check your email to get the code',
  },
  reset_password: {
    reset_password: 'Enter New Password',
    Password_does_not_match: 'Password does not match',
    invalid_username_or_resetKey: 'Invalid username or reset password key',
    link_expired: 'The link you followed has expired',
    new_password_must_be_different_recent_password:
      'Your new password must be different from the recent password',
    new_password_must_be_different_current_password:
      'New password must be different from the current password',
  },
  forgot_password: {
    forgot_password: 'Forgot password?',
    enter_your_email: 'Enter Your Email',
    forgot_password_title: 'Forgot Password',
    popup_content: 'Please check your email to reset your password. Thank you!',
    require_email: 'Email is required',
  },
  change_password: {
    confirm_pass_does_not_match: 'Confirm password does not match',
    current_password_incorrect: 'Current password is incorrect',
  },
  account: {
    enter_email: 'Enter Your Mail',
    email: 'Email',
    enter_username: 'Enter Your UserName',
    username: 'UserName',
    enter_password: 'Enter Your Password',
    password: 'Password',
    create_account: 'Create Account',
    old_password: 'Current Password',
    new_password: 'New Password',
  },
  permission: {
    not_permission: 'Sorry, you are not allowed to access this page',
    your_ip: 'Your IP',
    not_allowed: 'not allowed to access this page',
    not_allowed_room: 'Your IP not allowed to access this room',
    button_back_home: 'Go to Homepage',
    button_back_login: 'Go to Login',
    many_login_attempts:
      'Sorry, your account has been temporarily locked due to too many incorrect password attempts. Please contact the administrator to unlock your account',
    not_allowed_in_system: 'not allowed to access this system',
  },
  prejoin: {
    go_to_admin: 'Go to Admin',
    choose_your_room: 'Choose Your Room',
    sorted_by: 'Sorted By',
    name_a_z: 'A-Z',
    name_z_a: 'Z-A',
    time_new_to_old: 'Newest to Oldest',
    time_old_to_new: 'Oldest to Newest',
    send_request_add_key_device: 'Request Via Device',
    popup_send_request_add_key_device: 'Do you want send request add Device Key?',
    has_send_request: 'You have submitted a request before',
  },
  roompage: {
    url_token_required: 'Url Server and Token Room are required',
    exit: 'Exit',
    export: 'Export',
    chat_conversation: 'Chat conversation',
    conversation_report: 'Conversation report',
    select_export: 'Select export option',
    stop_translations: 'Stop translations (video call)',
    translate_video_and_support: 'Translate video calls and provide support',
    font_size: 'Font size',
    translate_chat: 'Translate chat',
    translate_video_call: 'Translate video call',
    language: 'Language',
    press_enter_to_send: 'Press [Ctrl + Enter] to send a message',
    translating: 'Translating....',
    edited: '(edited)',
    suggest: 'Click to automatically improve your messages',
    see_more: 'see more',
    see_less: 'see less',
    allow_read_image: 'Allows pasting images into this room',
    max_image_size: 'Image size exceeds 6MB limit',
    max_image_length: 'Can only send a maximum of 1 photo',
    modal_image_to_text: 'Convert image to text',
    from_language: 'From',
    to_language: 'To',
    image: 'Image',
    original_text: 'Original Text',
    translated_text: 'Translated Text',
    validate_select_language: 'Please select a from language first.',
    retranslate_message: 'Click to retranslate this message',
    language_translate: 'Translate to',
    turn_on_off_video_call: 'Turn on/off video call (Ctrl + Q)',
    allow_paste_image : 'Paste Image',
    allow_AI : 'AI Helper',
    function : 'Function',
  },
  message_common: {
    account_locked:
      'Sorry, your account has been temporarily locked due to too many incorrect {{item_name}} attempts. Please contact the administrator to unlock your account',
    item_does_not_match: '{{item_name}} does not match',
    function_successsfully: '{{function_name}} successfully',
    item_invalid: '{{item_name}} is invalid',
    already_exits: '{{item_name}} already exists',
    not_allowed: '{{item_name}} not allowed to access this page',
    confirm_set_status: 'Do you want to change the {{item_name}}',
    confirm_delete: 'Do you want to delete this {{item_name}}',
    deleted_messages: 'This {{item_name}} is deleted!',
    not_exists: '{{item_name}} not exists',
    function_failed: '{{function_name}} failed',
    confirm_set_status_ip: `You're moving the system to a {{item_name}}. Are you certain you'd like to proceed?`,
    note_set_status_ip_public: `※Once this transition is made, all active accounts will have the ability to log in from any location.`,
    note_set_status_ip_private: `※Once this transition is made, only active accounts with authorized IP will be able to access the system.`,
  },
  item_name: {
    confirm_password: 'Confirm password',
    password: 'Password',
    account_locked_password: 'password',
    OTP: 'OTP',
    username: 'Username',
    email: 'Email',
    room_name: 'Room name',
    ip_address: 'IP address',
    user_status: `user's status`,
    user_block_login_status: `user's block login status`,
    user_block_OTP_status: `user's block OTP status`,
    IP_status: 'status of this Publick IP',
    message: 'message',
    user: 'user',
  },
  profile: {
    update_profile_failed: 'Update Profile is failed',
    file_size_and_formats_info: 'File Upload: Max 5MB.',
    only_image: 'PNG, JPG, JPEG only.',
  },

  vocabulary: {
    vietnamese: 'Vietnamese',
    japanese: 'Japanese',
  },

  uuid_management: {
    accept_button: 'Accept',
    uuid_management: 'Device Key Management',
    confirm_accept: 'Do you want accept this device key?',
    device_address_not_found: 'Device key not exists',
    confirm_allowed: 'Do you want to change the status of this Device Key?',
    key_device: 'Device Key',
    user_send_request: 'User Send Request',
    user_accept_request: 'User Accept Request',
    created_at: 'Created At',
    is_accept: 'Accept Request',
    is_allowed: 'Status',
    accept_failed: 'Accept Device Key failed',
    send_device_failed: 'Send Device Key failed',
  },

  api_call_history: {
    api_call_history_management: 'Action History Management',
    endpoint: 'End Point',
    requestMethod: 'Method',
    payload: 'Payload',
    browser: 'Browser',
    clientIp: 'Client IP',
    username: 'Username',
    created_at: 'Created At',
    date_from_must_be_less_than_date_to: 'Date from must be less than date to',
    date_to_must_be_greater_than_date_from: 'Date to must be greater than date from',
    action_history_detail: 'Action history detail',
    detail: 'Detail',
  },

  ai_helper: {
    review_code: 'Review Code',
    data_example_api: 'Data Example',
    current_code: 'Current Code',
    code_review_result: 'Code Review Result',
    key: 'Key',
    type: 'Type',
    action: 'Actions',
    note: 'Description',
    description_of_data: 'Description of data',
    data_example_result: 'Data example result',
    export_csv_file: 'Export CSV file',
    content_chat_bot: 'Click to improve your code',
    rows_data_example_return: 'Number of examples to return:',
    invalid_option: 'Invalid Option',
  },
};
