import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import './assets/css/PreJoinPage.css';
import './assets/css/RoomPage.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from "redux/store/store";
import { BrowserRouter } from 'react-router-dom';

const root = createRoot(document.getElementById('root') as Element);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);