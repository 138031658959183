export function formatMentions(inputValue) {
  const mentionRegex = /@\[([^\]]+)\]\((\d+)\)/g;

  const formattedValue = inputValue.replace(
    mentionRegex,
    (_match, username, _id) => `@${username} `,
  );

  return formattedValue;
}

export const replaceNewlinesWithBr = (text: string) => {
  return text.replaceAll('\n', '<br>');
};

export const removeHTMLTags = (str: string) => {
  return str
    .replaceAll(/<br\s*\/?>/g, '\n')
    .replace('<span style="color: #845bcf; font-style: italic !important;">', '')
    .replace('</span>', '');
};

export const checkStringMention = (inputString: string) => {
  const mentionRegex = /@(\w+)/g;
  const cleanedString = inputString.replace(mentionRegex, '');

  return cleanedString.trim() === '';
};

export const replaceBrtoNewLine = (text: string) => {
  return text.replaceAll('<br>', '\n');
};