import { regex } from '../constants'

export const isEmpty = (value: string) => {
  if (!value) {
    return !value
  }
}

export const isEmail = (value: string) => {
  if (regex.RULE.EMAIL.test(value)) {
    return true
  }

  return false
}

export const isNumber = (value: string) => {
  if (regex.RULE.NUMBER.test(value)) {
    return true
  }

  return false
}

export const isPassword = (value: string) => {
  if (regex.RULE.PASSWORD.test(value)) {
    return true
  }

  return false
}

export const isValidIp = (value: string) => {
  if (regex.RULE.PUBLIC_IP.test(value)) {
    return false
  }

  return true
}