import { toast } from "react-toastify";

const showToast = (message: string, type: 'success' | 'error' | 'warning', time?: number ) => {
    switch (type) {
        case 'success':
            toast.success(message, {
                position: 'top-right',
                autoClose: time ?? 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: 'light',
              });
            break;
        case 'error':
            toast.error(message, {
                position: 'top-right',
                autoClose: time ?? 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: 'light',
              });
            break;
        case 'warning':
            toast.warning(message, {
                position: 'top-right',
                autoClose: time ?? 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: 'light',
                });
            break;
        default:
            break;
    }
}

export default showToast
