import { axios } from 'utils/plugins';
import { apiRoutes } from '.';

export const fetchApiLogin = async (data: ILoginDataAPI) => {
  const result = await axios.post(apiRoutes.LOGIN, data);
  return result;
};

export const fetchVerifyOTP = async (data: IVerifyOTPDataAPI) => {
  const result = await axios.post(apiRoutes.VERIFY_OTP, data);
  return result;
};

export const fetchApiChangePassword = async (data: IDataChangePasswordAPI) => {
  const result = await axios.put(`${apiRoutes.CHANGE_PASSWORD}`, data);
  return result;
};

export const fetchApiSendEmailForgotPassword = async (data: IDataForgotPasswordEmailAPI) => {
  const result = await axios.post(`${apiRoutes.SENT_EMAIL_FORGOT_PASSPORT}`, data);
  return result;
};

export const fetchApiListAccount = async (params: any) => {
  const result = await axios.get(apiRoutes.LIST_ACCOUNT, { params: params });
  return result;
};
export const fetchApiResetPassword = async (data: IResetPasswordDataAPI) => {
  const result = await axios.post(`${apiRoutes.RESET_PASSWORD}`, data);
  return result;
};

export const fetchApiAddAccount = async (data: IAddAccountDataAPI) => {
  const result = await axios.post(apiRoutes.ADD_ACCOUNT, data);
  return result;
};
export const fetchCreateRoom = async (data: ICreateRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.ROOM}`, data);
  return result;
};

export const fetchUpdateRoom = async (data: IUpdateRoomDataAPI) => {
  const result = await axios.put(`${apiRoutes.ROOM}`, data);
  return result;
};

export const fetchGetListRoom = async (params: any) => {
  const result = await axios.get(`${apiRoutes.ROOM}`, { params: params });
  return result;
};

export const fetchUpdateMemberInRoom = async (data: IUpdateMemberRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.UPDATE_MEMBER_OF_ROOM}`, data);
  return result;
};

export const fetchApiCheckSetPasswordKey = async (data: ICheckSetPasswordKeyDataAPI) => {
  const result = await axios.post(`${apiRoutes.CHECK_SET_PASSWORDKEY}`, data);
  return result;
};

export const fetchGetListRoomByAccount = async (params: any) => {
  const result = await axios.get(`${apiRoutes.LIST_ROOM_BY_ACCOUNT}`, { params: params });
  return result;
};

export const fetchApiCreateTokenRoom = async (data: ICreateTokenRoomAPI) => {
  const result = await axios.post(apiRoutes.CREATE_TOKEN_ROOM, data);
  return result;
};

export const fetchCheckUserExistInRoom = async (data: ICheckUserExistInRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.CHECK_USER_EXIST_IN_ROOM}`, data);
  return result;
};

export const fetchDataChatInRoom = async (params: any) => {
  const result = await axios.get(apiRoutes.MESSAGE_BY_ROOM, { params: params });
  return result;
};

export const fetchTranslateImage = async (data: IFetchTranslateImageDataAPI) => {
  const result = await axios.post(apiRoutes.TRANSLATE_IMAGE, data);
  return result;
};

export const fetchListLanguage = async (params: any) => {
  const result = await axios.get(apiRoutes.LANGUAGE, { params: params });
  return result;
};

export const fetchCheckPublicIpAllowed = async (params: any) => {
  const result = await axios.get(apiRoutes.CHECK_PUBLIC_IP_ALLOWED, {
    params: { publicIpAddress: params },
  });
  return result;
};

export const fetchGetPublicIpAddress = async () => {
  const result = await axios.get(`https://api.ipify.org?format=json`);
  return result;
};

export const fetchSubcriptionToServer = async (data: any) => {
  const result = await axios.post(apiRoutes.SUBSCRIPTION, data);
  return result;
};

export const fetchSetActive = async (data: ISetActiveAPI) => {
  const result = await axios.post(apiRoutes.SET_ACTIVE, data);
  return result;
};

export const fetchSetBockLogin = async (data: ISetBlockLoginAPI) => {
  const result = await axios.post(apiRoutes.SET_BLOCK_LOGIN, data);
  return result;
};

export const fetchListAccountForUpdateToRoom = async (params: any) => {
  const result = await axios.get(apiRoutes.LIST_ACCOUNT_FOR_UPDATE_TO_ROOM, { params: params });
  return result;
};

export const fetchGetListPublicIpByUser = async (params: any) => {
  const result = await axios.get(`${apiRoutes.GET_LIST_PUBLIC_IP_BY_ACCOUNT}`, {
    params: params,
  });
  return result;
};

export const fetchUpdatePublicIPInRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.ADD_OR_REMOVE_IP}`, data);
  return result;
};

export const fetchAddNewPublicIPInRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.ADD_NEW_PUBLIC_IP}`, data);
  return result;
};

export const fetchListIdAdminInRoom = async (data: any) => {
  const result = await axios.get(`${apiRoutes.GET_LIST_ID_ADMIN_IN_ROOM}`, data);
  return result;
};

export const fetchAddAdminToRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.ADD_ID_ADMIN_IN_ROOM}`, data);
  return result;
};

export const fetchCheckIsAdminRoom = async () => {
  const result = await axios.get(`${apiRoutes.CHECK_ADMIN_ROOM}`);
  return result;
};

export const fetchListPubLicIP = async (params: any) => {
  const result = await axios.get(apiRoutes.FIND_ALL_PUBLIC_IP, { params: params });
  return result;
};

export const fetchSetAllowed = async (data: any) => {
  const result = await axios.post(apiRoutes.SET_ALLOWED_PUBLIC_IP, data);
  return result;
};

export const fetchAddNewPublicIP = async (data: any) => {
  const result = await axios.post(apiRoutes.ADD_NEW_PUBLIC_IP_ADRESS, data);
  return result;
};

export const fetchApiMain = async () => {
  const result = await axios.get(`${apiRoutes.API_MAIN_HELLO}`);
  return result;
};

export const fetchSetBlockOTP = async (data: ISetBlockOTPAPI) => {
  const result = await axios.post(apiRoutes.SET_BLOCK_OTP, data);
  return result;
};
export const fechSuggestMessage = async (params: any) => {
  const result = await axios.post(apiRoutes.SUGGEST_MESSAGE, { text: params });
  return result;
};

export const fetchCheckIpInRoom = async (params: any) => {
  const result = await axios.get(apiRoutes.CHECK_IP_IN_ROOM, { params: params });
  return result;
};
export const fetchGetProfile = async (params: any) => {
  const result = await axios.get(apiRoutes.GET_PROFILE, { params: params });
  return result;
};

export const fetchUpdateProfile = async (params: any) => {
  const result = await axios.post(apiRoutes.UPDATE_PROFILE, params);
  return result;
};

export const fetchGetListVocabularyInRoom = async (params: any) => {
  const result = await axios.get(`${apiRoutes.VOCABULARY_IN_ROOM}`, {
    params: params,
  });
  return result;
};

export const fetchAddVocabularyInRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.VOCABULARY_IN_ROOM}`, data);
  return result;
};

export const fetchUpdateVocabularyInRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.VOCABULARY_UPDATE}`, data);
  return result;
};

export const fetchDeletedVocabularyInRoom = async (data: any) => {
  const result = await axios.post(`${apiRoutes.VOCABULARY_DELETED}`, data);
  return result;
};

export const fetchCheckIsAdminRoomByRoomId = async (params: any) => {
  const result = await axios.get(`${apiRoutes.CHECK_ADMIN_ROOM_BY_ID}`, {
    params: params,
  });
  return result;
};

export const fetchSetAllowReadImageInRoom = async (data: ISetAllowReadImageRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.SET_ALLOW_READ_IMAGE}`, data);
  return result;
};

export const fetchSetAllowAiHelperInRoom = async (data: ISetAllowAiHelperRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.ALLOW_AI_HELPER}`, data);
  return result;
};

export const fetchGetListIpForUpdateByUser = async (params: any) => {
  const result = await axios.get(`${apiRoutes.GET_LIST_IP_FOR_UPDATE}`, {
    params: params,
  });
  return result;
};

export const fetchChangeStatusSystemIP = async (data: any) => {
  const result = await axios.post(apiRoutes.CHANGE_STATUS_SYSTEM_IP, data);
  return result;
};

export const fetchGetSystemIP = async (params: any) => {
  const result = await axios.get(`${apiRoutes.SYSTEM_IP}`, {
    params: params,
  });
  return result;
};

export const fetchGetPublicIPInRoom = async (params: any) => {
  const result = await axios.get(`${apiRoutes.GET_LIST_IP_IN_ROOM}`, {
    params: params,
  });
  return result;
};

export const fetchUpdatePublicIPInRoomToSystem = async (data: any) => {
  const result = await axios.post(`${apiRoutes.ADD_OR_REMOVE_IP_IN_SYSTEM}`, data);
  return result;
};

export const fetchApiListUuid = async (params: any) => {
  const result = await axios.get(apiRoutes.DEVICE_ADDRESS, { params: params });
  return result;
};

export const fetchSendRequestAddDeviceKey = async (data: any) => {
  const result = await axios.post(`${apiRoutes.SEND_DEVICE_ADDRESS}`, data);
  return result;
};

export const fetchAcceptDeviceKey = async (data: any) => {
  const result = await axios.post(`${apiRoutes.ACCEPT_DEVICE_ADDRESS}`, data);
  return result;
};

export const fetchAllowedDeviceKey = async (data: any) => {
  const result = await axios.post(`${apiRoutes.SET_STATUS_DEVICE_ADDRESS}`, data);
  return result;
};

export const fetchAllowDeviceKey = async (data: any) => {
  const result = await axios.post(`${apiRoutes.ALLOW_DEVICE_ADDRESS}`, data);
  return result;
};

export const fetchSetAllowedPublicIP = async (data: any) => {
  const result = await axios.post(`${apiRoutes.SET_ALLOWED_LIST_PUBLIC_IP}`, data);
  return result;
};

export const fetchChangeRoleAccount = async (data: IChangeRoleAccountDataAPI) => {
  const result = await axios.post(apiRoutes.UPDATE_ROLE, data);
  return result;
};

export const fetchRefreshToken = async (data: any) => {
  const result = await axios.post(apiRoutes.REFRESH_TOKEN, data);
  return result;
};

export const fetchListApiCallHistory = async (page: number, dto: any) => {
  const result = await axios.get(apiRoutes.API_CALL_HISTORY, { params: { page, dto } });
  return result;
};

export const fetchAddKeyLogin = async (data: IAddKeyLoginDataAPI) => {
  const result = await axios.post(apiRoutes.KEY_LOGIN, data);
  return result;
};

export const fetchGetRoomRoomById = async (data: ISetAllowReadImageRoomDataAPI) => {
  const result = await axios.post(`${apiRoutes.GET_ROOM_BY_ID}`, data);
  return result;
};

export const fetchCreateDataExample = async (data: any) => {
  const result = await axios.post(`${apiRoutes.CREATE_DATA_EXAMPLE}`, data);
  return result;
};
export const fetchAIhelperReviewCode = async (data: any) => {
  const result = await axios.post(apiRoutes.AI_HELPER_REVIEW_CODE, data);
  return result;
};
export const fetchListBadword = async () => {
  const result = await axios.get(`${apiRoutes.BAD_WORD}`);
  return result;
};

export const fetchGetListAIHelper = async (data: any) => {
  const result = await axios.get(`${apiRoutes.AI_HELPER}`, data);
  return result;
};
