import React from 'react';
import './NotFoundAndPermission.scss';
import { CContainer } from '@coreui/react-pro';
import { WARNING_ICON} from 'utils/constants/images';
import Button from 'components/common/button/Button';
import { useNavigate } from 'react-router-dom';
import {authHelper} from 'utils/helpers';
import { useTranslation } from 'react-i18next';

const ManyLoginAttempts = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
      navigate('/');
  };
  return (
    <CContainer>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="notfound-permission">
          <div className="d-flex justify-content-center">
            <img src={WARNING_ICON} alt="" height={100} />
          </div>
          <div className='text-center mt-1'>
          <p>{t('permission.many_login_attempts')}</p>
          </div>
          <Button
            className="button-custom-back"
            label={t('permission.button_back_home')}
            onClick={handleClick}        
          />
        </div>
      </div>
    </CContainer>
  );
};

export default ManyLoginAttempts;
