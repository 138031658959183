export const LS_EMAIL = 'email';
export const LS_PASSWORD = 'password';
export const LS_LANGUAGE = 'lang';
export const LS_REMEMBER_LOGIN = 'remember_login';
export const LS_USER_INFO = 'userInfo';
export const LS_KEY_BROSWER = 'key_browswer';
export const LS_IP_LOGIN = 'ip_login';
export const LS_ID_MESSAGE = 'id_message';
export const LS_LANGUAGE_FROM = 'language_from';
export const LS_IS_SEND_ADD_DEVICE = 'is_send_add_device';
