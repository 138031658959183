import CryptoJS from 'crypto-js';

export const autoFormatTextInput = (stringValue?: string): string => {
  if (stringValue) {
    let words = stringValue.split(' ');
    words = words.filter((word, index) => word !== '' || index === words.length - 1);
    return words.join(' ');
  }
  return '';
};

export const hashString = (inputString: string) => {
  const algorithm = process.env.REACT_APP_ALGORITHM_HASH_STRING;
  const hash = CryptoJS[algorithm](inputString).toString(CryptoJS.enc.Hex);
  return hash.toString();
};

export const getCurrentDateTime = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

export const randomKeyWithDatetime = (key: string): string => {
  const currentTimeStr = getCurrentDateTime();
  const keyBase = `${key}${currentTimeStr}`;
  const randomKey = Array.from(
    { length: 10 },
    () => keyBase[Math.floor(Math.random() * keyBase.length)],
  ).join('');
  return keyBase + randomKey;
};

export const genericUUID = (inputString: string) => {
  const randomKey = randomKeyWithDatetime(inputString);
  const uuid = String(hashString(randomKey) + getCurrentDateTime());
  return uuid;
};

export const generateRandomKey = () => {
  const currentTime = Date.now().toString();
  const randomKey = currentTime
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('')
    .substr(0, 16);
  return randomKey;
};
