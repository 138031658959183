import { combineReducers } from 'redux'
import { changeStateReducer, loaderReducer, modalReducer } from './common'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducers = combineReducers({
  changeState: changeStateReducer,
  loaderState: loaderReducer,
  modalState: modalReducer,
})
// export type any = ReturnType<typeof rootReducers>
const persistedReducer = persistReducer(persistConfig, rootReducers)

export default persistedReducer
