import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import rootReducers from 'redux/reducers'

type AppState = ReturnType<typeof rootReducers>

type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>

export const useAppDispatch = () => useDispatch<TypedDispatch<AppState>>()

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
