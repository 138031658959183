import en from './en'
import vn from './vn'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { LS_LANGUAGE } from 'utils/constants/localStorage'
import { enums } from 'utils/constants'

const resources = {
  en: {
    translation: en,
  },
  vn: {
    translation: vn,
  },
}

const language = localStorage.getItem(LS_LANGUAGE) ?? enums.LANGUAGE.ENGLISH

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources,
    lng: language,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
