export const enum LANGUAGE {
  JAPAN = 'jp',
  ENGLISH = 'en',
  VIETNAM = 'vn',
}

export const usernameRegex = /@(\w+)/;

export const enum ROLE_ACCOUNT {
  ADMIN = 'super_admin',
  ADMIN_ROOM = 'admin_room',
  USER = 'user',
}