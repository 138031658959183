import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { log } from 'console';
import { SET_MODAL } from 'redux/actions/type';
import { store } from 'redux/store/store';
import { http } from 'utils/constants';
import { LS_IP_LOGIN } from 'utils/constants/localStorage';
import { authHelper, urlRoutes } from 'utils/helpers';
import { getDeviceKey } from 'utils/helpers/auth';

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_HOST,
});

axiosConfig.interceptors.request.use(
  async (config: AxiosRequestConfig | any) => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      if (response.data.ip) {
        const ip = response.data.ip;
        localStorage.setItem(LS_IP_LOGIN, ip);
        config.headers['public-ip'] = ip;
        config.headers['key-device'] = getDeviceKey();
      } else {
        const response = await axios.get('https://httpbin.org/ip');
        const ip = response.data.origin;
        localStorage.setItem(LS_IP_LOGIN, ip);
        config.headers['public-ip'] = ip;
        config.headers['key-device'] = getDeviceKey();
      }
    } catch (error) {
      console.error('Error getting IP', error);
    }
    
    if (authHelper.isAuth()) {
      config.headers = {
        ...config.headers,
        Authorization: authHelper.accessToken(),
        'browser-key': authHelper.browserKey(),
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      };
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

axiosConfig.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const { response } = error;
    if (response && response?.status !== http.SUCCESS_CODE) {
      if (response?.status === http.AUTHENTICATION_FAIL_CODE) {
        if (response.data?.['message'] === 'CLIENT_IP_NOT_ALLOWED_IN_SYSTEM') {
          window.location.href = urlRoutes.NOT_ALLOWED_IN_SYSTEM;
        } else if (response.data?.['message'] === 'CLIENT_IP_NOT_ALLOWED') {
          window.location.href = urlRoutes.NOT_ALLOWED;
        } else if (response.data?.['message'] === 'Logout User') {
          authHelper.logOut();
          window.location.replace('/login');
        } else if (response.data?.['message'] === 'Token Invalid') {
          const isNotCallApi = true;
          authHelper.logOut(isNotCallApi);
          window.location.replace('/login');
        } else if (response.data?.['message'] === 'Token Expired') {
          const isNotCallApi = true;
          authHelper.logOut(isNotCallApi);
          window.location.replace('/login');
        } else if (response.data?.['message'] === 'Not permission') {
          window.location.href = urlRoutes.NOT_PERMISSION;
        } else if (response.data?.['message'] === 'not_permission') {
          window.location.href = urlRoutes.NOT_PERMISSION;
        } else {
          window.location.href = urlRoutes.NOT_PERMISSION;
          // return store.dispatch({
          //   type: SET_MODAL,
          //   isShowToast: 'Is Show Toast',
          // });
        }
      } else if (response?.status == http.NOT_ALLOW_CODE) {
        window.location.href = urlRoutes.NOT_PERMISSION;
      }
      return Promise.reject(response);
    }

    return Promise.reject(response);
  },
);

export default axiosConfig;
