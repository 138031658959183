/* eslint-disable import/no-anonymous-default-export */
export default {
  base: {
    record: 'record',
    records: 'records',
    password: 'Password',
    confirm_password: 'Confirm Password',
    email: 'Email Address',
    work_start: 'Work Start',
    work_end: 'Work End',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
  },
  nav: {
    admin: 'Admin',
    branch_list: 'Branch Management',
    group: 'Group Management',
    user_list: 'User List Management',
    team_management: 'Create Team Name',
    team_name_manager: 'Team Name Management',
    add_new_user: 'Add New User',
    user_log: 'User Log',
    permission_access: 'Permission Access',
    city_list: 'City List Management',
    setting_call_block: 'Setting Call Block',
    extension_white_list: 'Extension White List',
  },
  user_list: {
    add_news_user: 'Add News User',
    edit_user: 'Edit User',
    import_user_list: 'Import User List',
    id_username: 'ID/Username',
    ext: 'EXT',
    full_name: 'Full Name',
    team_management: 'Team Name',
    type_of_user: 'Type Of User',
  },
}
