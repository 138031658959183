const ROOM = '/room';
const LOGIN = '/login';
const LOGIN_USER = '/login-user';
const VERIFY_OTP = '/verify-otp';
const RESET_PASSWORD = '/reset-password';
const ACCOUNT_MANAGEMENT = '/admin/account-management';
const ROOM_MANAGEMENT = '/admin/room-management';
const NOT_FOUND = '/not-found';
const NOT_PERMISSION = '/not-permission';
const NOT_ALLOWED = '/not-allowed';
const CHANGE_PASSWORD = '/change-password';
const FORGOT_PASSWORD = '/forgot-password';
const PUPLIC_IP_MANAGEMENT = '/admin/public-ip-management';
const MANY_LOGIN_ATTEMPTS = '/many-login-attempts';
const NOT_ALLOWED_IN_SYSTEM = '/not-allowed-in-system';
const UUID_MANAGEMENT = '/admin/uuid-management';
const HISTORY_MANAGEMENT = '/admin/history-management';
export {
  ROOM,
  LOGIN,
  VERIFY_OTP,
  RESET_PASSWORD,
  ACCOUNT_MANAGEMENT,
  ROOM_MANAGEMENT,
  LOGIN_USER,
  NOT_FOUND,
  NOT_PERMISSION,
  NOT_ALLOWED,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  PUPLIC_IP_MANAGEMENT,
  MANY_LOGIN_ATTEMPTS,
  NOT_ALLOWED_IN_SYSTEM,
  UUID_MANAGEMENT,
  HISTORY_MANAGEMENT,
};
