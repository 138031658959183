import { CLEAR_MODAL, SET_LOADER, SET_MODAL } from 'redux/actions/type'

interface state {
  sidebarShow: boolean
  sidebarUnfoldable: boolean
  asideShow: boolean
  theme: string
}

const initialState: state = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  asideShow: false,
  theme: 'default',
}

const initStateModal: StateModal = {
  title: '',
  visible: false,
  buttonOK: '',
  alignment: 'center',
  size: 'sm',
  hiddenIconX: true,
  message: '',
  notFound: false,
  logOut: false,
  isSessionExpired:false,
  isShowToast: ''
}

type args = { type?: string; [key: string]: any }

const changeStateReducer = (state = initialState, { type, ...rest }: args) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const loaderReducer = (state: boolean = false, action: ILoaderReduxAction) => {
  switch (action.type) {
    case SET_LOADER:
      return action.data
    default:
      return state
  }
}

const modalReducer = (state = initStateModal, { type, ...rest }: args) => {
  switch (type) {
    case SET_MODAL:
      return { ...state, ...rest }
    case CLEAR_MODAL:
      return { ...initStateModal, ...rest }
    default:
      return state
  }
}

export { changeStateReducer, loaderReducer, modalReducer }
