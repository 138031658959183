import React from 'react'
import { CButton } from '@coreui/react-pro'
import { CButtonProps } from '@coreui/react-pro/dist/components/button/CButton'

interface Props extends CButtonProps {
  label: string
  icon?: any
  className?: string // Prop css
  fontSize?: number
  background?: string
  textColor?: string
  fontWeight?: number
  borderColor?: StringConstructor
  endIcon?: boolean
  startIcon?: boolean
  width?: number
  height?: number
  iconColor?: string
}

const Button: React.FC<Props> = ({
  label,
  className,
  icon,
  fontSize,
  background,
  textColor,
  fontWeight,
  borderColor,
  width,
  height,
  onMouseOver,
  onMouseOut,
  onClick,
  ...rest
}) => {
  return (
    <CButton
      {...rest}
      className={`components__button bases__font--${fontSize} bases__background--${background} bases__text--${textColor} bases__text--${fontWeight} bases__border--${borderColor} ${
        width ? `bases__width--${width}` : ''
      } ${height ? `bases__height--${height}` : ''} ${className}`}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
    >
      {icon && (
        <div className={`icon_btn ${!label ? 'me-0' : ''}`}>
          <img src={icon} alt="" />
        </div>
      )}
      <div>{label}</div>
    </CButton>
  );
};

export default Button;
